

















































import Vue from 'vue';
import Component from 'vue-class-component';
import {authModule} from '@/store/modules';
import {ResourceType, Route} from '@/enums';
import {InputValidationRules} from 'vuetify';
import {VForm} from '@/interfaces';

@Component
export default class Authentication extends Vue {
  email = '';
  emailRules: InputValidationRules = [
    v => !!v || 'E-mail is required',
    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
  ];
  feedback: string | null = null;
  password = '';
  passwordRules: InputValidationRules = [
    v => !!v || 'Password is required',
    v => String(v).length >= 6 || 'Password is too short',
  ];
  valid = false;

  get authenticated(): boolean {
    return authModule.isAuthenticated;
  }

  get authenticating(): boolean {
    return authModule.isAuthenticating;
  }

  get loading(): boolean {
    return authModule.isLoading;
  }

  async created(): Promise<void> {
    try {
      await authModule.checkAuth();

      if (this.authenticated) {
        console.log('Already authenticated');
        await this.redirect();
      }
    } catch (err) {
      console.log('cccc');
      console.error(err);
    }
  }

  async submit(): Promise<void> {
    const form = this.$refs.form as VForm;
    if (!form.validate()) {
      return;
    }

    try {
      await authModule.authenticate({
        type: ResourceType.Auth,
        attributes: {
          email: this.email as string,
          password: this.password as string,
          provider: 'password',
        },
      });
      console.log('Authentication successful');

      try {
        await this.redirect();
      } catch (err) {
        console.error(err);
        this.feedback = 'Route error';
      }
    } catch (error) {
      console.error(error);
      this.feedback = error.response.data.errors[0].detail;
    }
  }

  async redirect(): Promise<void> {
    const redirect = !this.$route.query.redirect
      ? {name: Route.Dashboard}
      : decodeURIComponent(this.$route.query.redirect as string);
    await this.$router.push(redirect);
  }
}
